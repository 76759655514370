import React, { useContext, useEffect, createRef } from "react";
import { Button, Section, Text } from "../components/Core";
import { navigate } from "gatsby"
import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import Seo from "../components/Seo";

const Maintenance = () => {
    
    return (
        <>
            <Seo page="success-csat" />
            <Section className="green-bg text-center">
                <Container className="position-relative">
                    <Row className="justify-content-center mt-50 mb-50">
                        <Col sm={12}>
                            <h3>This page is under maintenance.</h3>
                            <h4>Please try again later or</h4>
                            <Button
                              onClick={() => (typeof window !==  "undefined") && document.getElementsByClassName('YFChat')[0].click()}
                              className="mt-50 blue-bg"
                            >
                              Send us a message
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    )
}

export default Maintenance